<template>
  <div class="contact">
    <Nav />
    <ContactForm />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import ContactForm from "@/components/contactPage.vue";
export default {
  name: "Contact",
  components: {
    Nav,
    Footer,
    ContactForm
  },
  metaInfo: {
    title: "SeismicCore | Contact",
    meta: [
      { name: "theme-color", content: "#57b3fd" },
      { property: "og:title", content: "SeismicCore | Contact" },
      {
        property: "og:description",
        content: "Contact the team at SeismicCore."
      },
      {
        name: "description",
        content: "Contact the team at SeismicCore.",
        id: "desc"
      },
      { name: "twitter:title", content: "SeismicCore | Contact" },
      {
        name: "twitter:description",
        content: "Contact the team at SeismicCore."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
