


























































































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";

// @ts-ignore 
import { mdbInput } from "mdbvue";

@Component({
  components: {
    mdbInput
  }
})

export default class ContactForm extends Vue {
  firstName: string = "";
  lastName: string = "";
  company: string = "";
  email: string = "";
  phone: string = "";
  subject: string = "";
  body: string = "";
  valid: boolean = false;
  
  /* eslint-enable */
  validateEmail() {
    if (
      !this.firstName ||
      !this.lastName ||
      !this.email ||
      !this.subject ||
      !this.body
    )
      return;
    else if (!this.email.includes("@") || !this.email.includes(".")) {
      return;
    } else {
      this.valid = true;
      document?.getElementById("submit")?.classList.remove("defaultForm");
      document?.getElementById("submit")?.classList.add("validForm");
    }
  }

  launchEmail() {
    if (this.valid)
      window.open(`mailto:Contact@SeismicCore.com?subject=
    ${encodeURIComponent(this.subject)}&body=
      ${encodeURIComponent(this.firstName)} ${encodeURIComponent(
        this.lastName
      )}%0D%0A${
        this.company ? encodeURIComponent(this.company) + "%0D%0A" : ""
      }${encodeURIComponent(this.email)}${
        this.phone ? "%0D%0A" + encodeURIComponent(this.phone) : ""
      }%0D%0A%0D%0A---------------------------------------------%0D%0A%0D%0A${encodeURIComponent(
        this.body
      )}`);
  }
}
